import {Injectable} from '@angular/core';
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root',
})
export class AppToastService implements IToastProvider {

  constructor(private messageService: MessageService) {
  }

  error(error: string, sticky: false) {
    this.messageService.add({summary: 'Error', closable: true, detail: error, severity: 'error', sticky: sticky,});
  }

  success(message: string, sticky = false) {
    this.messageService.add({
      summary: 'Success',
      closable: true,
      detail: message,
      severity: 'success',
      sticky: sticky,
    });
  }
}
