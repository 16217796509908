import {Component, ElementRef, ViewChild} from '@angular/core';
import {ApiService} from "./common/services/api.service";

@Component({
  selector: 'app-india-election',
  standalone: true,
  imports: [],
  template: `
    <div class="image1" style="width: 100vw;height: 100vh;" #imageContainer>
      <object width="100%" height="100%" type="image/svg+xml" data="assets/map.svg" #svgObject></object>
    </div>

  `,
  styles: ``
})
export class IndiaElectionComponent {
  title = 'india-poll';

  @ViewChild('svgObject')
  svgObject!: ElementRef;

  constructor(public api: ApiService) {
    this.load();
  }

  ngAfterViewInit(): void {
    this.svgObject.nativeElement.addEventListener('load', () => {

      const svgDoc = this.svgObject.nativeElement.contentDocument;
      const paths = svgDoc.querySelectorAll('path');
      this.load();
      setInterval(() => {
        this.extracted(svgDoc);
      }, 3000)


      //
      // paths.forEach((path: {
      //   addEventListener: (arg0: string, arg1: (event: any) => void) => void;
      // }) => {
      //   path.addEventListener('click', (event: { target: any; }) => {
      //     // this.onCountryClick(event.target);
      //   });
      // });
    });
  }

  private extracted(svgDoc) {
    this.api.get('india-election', {}, false)
      .subscribe((res: any[]) => {
        res.forEach((f) => {
          var item = svgDoc.querySelectorAll('#' + f.id)[0]
          item.classList.remove(...item.classList);
          item.classList.add('land')
          item.classList.add(f.state)
        })
      })
  }

  public load() {


  }
}
