import { Injectable } from '@angular/core';
import { ConfirmationService, MessageService } from "primeng/api";

@Injectable({
  providedIn: 'root',
})
export class DecoratorService {
  private static confirmationService: ConfirmationService | undefined = undefined;
  private static messageService: MessageService | undefined = undefined;

  public constructor(confirmationService: ConfirmationService) {
    DecoratorService.confirmationService = confirmationService;
    console.log('decorating ');
  }

  public static check() {
    console.log('sample');
    this.confirmationService!.confirm({
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {

      },
      reject: () => {
        //reject action
      },
    })
  }

  public static getConfirmationService(): ConfirmationService {
    if (!DecoratorService.confirmationService) {
      throw new Error('DecoratorService not initialized');
    }
    return DecoratorService.confirmationService;
  }

  public static getMessageService(): MessageService {
    if (!DecoratorService.messageService) {
      throw new Error('DecoratorService not initialized');
    }
    return DecoratorService.messageService;
  }
}
