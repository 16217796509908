import {Injectable} from '@angular/core';
import {
  CanActivate,
  Route,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from "../services/auth.service";

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate {

  constructor(public authService: AuthService, public router: Router) {
  }

  canActivate(
    route: Route,
    state: RouterStateSnapshot
  ): any {

    if (this.authService.loggedIn()) return true;
    this.router.navigate(['/']).then();
    return false;
  }


}
