import {inject, Inject, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from "./common/components/login.component";
import {DashboardModule} from "./dashboard/dashboard.module";
import {AuthService} from "./common/services/auth.service";
import {AuthenticatedGuard} from "./common/guards/authenticated.guard";
import {UpdateProfileComponent} from "./dashboard/update-profile.component";
import {IndiaElectionComponent} from "./india-election.component";

const routes: Routes = [
  {path: '', loadComponent: () => LoginComponent},
  {path: 'india-election', loadComponent: () => IndiaElectionComponent},
  {path: 'create-account', loadComponent: () => UpdateProfileComponent, data: {register: 1}},
  {
    canActivate: [AuthenticatedGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    path: 'dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
