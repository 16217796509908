import {Component, Input} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {ControlErrorsDirective, FormActionDirective} from "@ngneat/error-tailor";
import {AuthService} from "../services/auth.service";
import {LoadingBtnComponent} from "../widgets/loading-btn.component";
import {finalize} from "rxjs";
import {Router, RouterLink} from "@angular/router";
import {NgEventBus} from "ng-event-bus";
import {USER_LOGIN} from "../app-events";
import {XsSuggestionsDirective} from "../pipes/xs-suggestions.directive";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, InputTextModule, FormActionDirective, ControlErrorsDirective, LoadingBtnComponent, NgOptimizedImage, XsSuggestionsDirective, RouterLink],
  template: `
      <div class="center-all h-full">
          <div class="p-8 m-2 w-[300px] bg-white dark:bg-slate-800 shadow-lg shadow-grey-50 rounded-lg">
              <img src="https://primeasianews.com/wp-content/themes/prime-asia-news/images/logo.svg" class="w-full mt-0"
                   height="20"/>
              <h2 class="text-center dark:text-slate-400">Login</h2>
              <form [formGroup]="loginForm" errorTailor (ngSubmit)="doLogin()">
                  <ng-template let-error let-text="text" #tpl> {{ error | json }} {{ text }} aa</ng-template>
                  <div class="form-group">
                      <label for="" class="form-label">Username</label>
                      <input type="text" formControlName="username" class="form-control">
                  </div>
                  <div class="form-group">
                      <label for="" class="form-label">Password</label>
                      <input type="password" formControlName="password" class="form-control">
                  </div>
                  <app-loading-btn [loading]="loading" label="Login" class="block-btn"></app-loading-btn>
                  {{checkState}}
                  <a [routerLink]="['/','create-account']"
                     class="text-center block mt-8 no-underline hover:text-red-500 dark:text-slate-400"
                     href="">Create Account</a>
              </form>
          </div>
      </div>
  `,
  styles: [
    `
      :host {
        @apply block h-full;
      }
    `,
  ],
})
export class LoginComponent {
  @Input() public checkState: any;
  public loading = false;
  public loginForm = new FormGroup({
    username: new FormControl<string>('', [Validators.required]),
    password: new FormControl<string>('', [Validators.required]),
  })

  constructor(public authService: AuthService, public router: Router, public eventBus: NgEventBus) {
  }

  doLogin() {
    this.loading = true;
    this.authService.login(<ILoginCredentials>this.loginForm.value)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (res) => {
          this.eventBus.cast(USER_LOGIN, res);
          this.authService.user = res;
          this.router.navigate(['/dashboard']).then();
        },
      });
  }
}
