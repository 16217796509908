import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class UserTimezoneInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let newReq = request.clone({
      setHeaders: {
        'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    });
    return next.handle(newReq);
  }
}

// write code to add timezone to request header using interceptor

