import {ErrorHandler, InjectionToken, NgModule, isDevMode} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DashboardComponent} from './dashboard.component';
import {provideErrorTailorConfig} from "@ngneat/error-tailor";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule, provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi} from "@angular/common/http";
import {EndpointPrefixInterceptor} from "./common/interceptors/endpoint-prefix.interceptor";
import {AppToastService} from "./common/services/app-toast.service";
import {ErrorInterceptor} from "./common/interceptors/error.interceptor";
import {ConfirmationService, MessageService} from "primeng/api";
import {ToastModule} from "primeng/toast";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {FilterByIdPipe} from './common/pipes/filter-by-id.pipe';
import {NgEventBus} from "ng-event-bus";
import {FileUploadModule} from "primeng/fileupload";
import {ServiceWorkerModule} from '@angular/service-worker';
import {ToastInterceptor} from "./common/interceptors/toast.interceptor";
import {ImageUploadComponent} from './common/components/image-upload.component';
import {provideImageKitLoader} from "@angular/common";
import {DialogService} from "primeng/dynamicdialog";
import {UserTimezoneInterceptor} from "./common/interceptors/user-timezone.interceptor";
import {provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig, withSessionStorage} from "ngx-webstorage";

export const TOAST = new InjectionToken('TOAST');

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ToastModule,
    ConfirmPopupModule,

    FileUploadModule,
    ServiceWorkerModule.register('service-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),

  ],
  providers: [
    provideHttpClient(withFetch(), withInterceptorsFromDi(),),
    provideNgxWebstorage(
      withNgxWebstorageConfig({separator: ':', caseSensitive: true}),
      withLocalStorage(),
      withSessionStorage()
    ),
    NgEventBus,
    DialogService,
    MessageService,
    ConfirmationService,
    provideImageKitLoader('https://ik.imagekit.io/aqcg4zaiu7s/'),
    {provide: HTTP_INTERCEPTORS, useClass: UserTimezoneInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: EndpointPrefixInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ToastInterceptor, multi: true},
    {provide: TOAST, useClass: AppToastService},
    provideErrorTailorConfig({
      errors: {
        useFactory() {
          return {
            required: 'This field is required',
            email: 'Invalid email address',
            confirmedValidator: 'Password dont match',
            passwordsNotMatch: 'Passwords dont match',
            minlength: ({requiredLength, actualLength}) => `Expect ${requiredLength} but got ${actualLength}`,
            invalidAddress: error => `Address not valid`,
          };
        },
        deps: [],
      },
      //controlErrorComponent: CustomControlErrorComponent, // Uncomment to see errors being rendered using a custom component
      //controlErrorComponentAnchorFn: controlErrorComponentAnchorFn // Uncomment to see errors being positioned differently
    }),
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
}
