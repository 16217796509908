import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppToastService} from "../services/app-toast.service";
import {map} from "rxjs/operators";

@Injectable()
export class ToastInterceptor implements HttpInterceptor {

  constructor(public toastService: AppToastService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {

        if (request.headers.get('toast') === 'false') return event;
        if (event instanceof HttpResponse) {
          if (event.body?.toast) {
            this.toastService.success(event.body.toast, false);
          }
        }
        return event;
      })
    );
  }
}
