import {Inject, Injectable, InjectionToken} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError} from "rxjs/operators";
import {TOAST} from "../../app.module";
import {Router} from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    @Inject(TOAST) public toastService: IToastProvider
    , public router: Router
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        let errorMessage;
        try {
          if (err.status == 419) {
            //reload the page
            window.location.reload();
          } else {
            if (err.status == 401) {
              // ask user to login again
              this.router.navigate(['/']).then();
              errorMessage = "Please Login Again"
            } else if (err.error && err.error['message']) {
              errorMessage = err.error['message'];
            } else {
              errorMessage = err.message;
            }
            this.toastService.error(errorMessage);
          }
        } catch (e) {
          this.toastService.error('An error occurred', '', {positionClass: 'toast-bottom-center'});
        }
      }
      return of(err);
    }));
  }
}
