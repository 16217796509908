import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class CsrfTokenService extends ApiService {

  getToken() { return this.http.get('csrf-cookie') }

}
